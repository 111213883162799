.vjs-theme-city .vjs-current-time,
.vjs-theme-city .vjs-time-divider,
.vjs-theme-city .vjs-duration {
  display: block !important;
  font-size: 1.5em;
  padding-left: 0.2em;
  padding-right: 0em;
  order: 1;
  line-height: 50px;
  text-align: center;
  min-width: 1em;
}

.vjs-theme-city .vjs-duration {
  color: yellow;
}

.vjs-theme-city .vjs-icon-cog:before {
  font-size: 1.5em;
}

.vjs-theme-city .result-bar {
  position: absolute;
  width: 100%;
  top: -30px;
}

.my-navigation {
  position: absolute;
  width: 100%;
  top: -70px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .my-navigation {
    top: -120px;
  }
}

.my-navigation-div {
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.my-button {
  margin: 5px;
  padding: 8px;
  font-size: 16px !important;
  background-color: #00851d !important;
  border: 1px solid #fff !important;
  cursor: pointer;
  border-radius: 5px;
}

.my-button.space {
  background-color: #0b35bf !important;
}

.my-button.reset {
  background-color: #a37300 !important;
}


.vjs-theme-city .result-div {
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
}

.vjs-theme-city .result-time {
  font-size: 1.3em;
}

.vjs-theme-city .result-answer {
  position: absolute;
  top: 30px;
  height: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1.3em;
}

.vjs-control-text {
  font-size: 1.5em;
}

.vjs-tech {
  pointer-events: none;
}

.live-chat-loader-placeholder {
  display: none !important;
}